@import "../base/vars";
@import "../base/mixins";

.main_content {
	display: flex;
	justify-content: center;
	align-items: stretch;

	@include max-width($tablet_wide) {
		min-height: calc(100vh - 84px);
	}
}

.error_404 {
	display: flex;

	@include max-width($tablet) {
		margin: 20px 0;
	}

	.wrapper {
		display: flex;
	}
	
	.h1 {
		margin-top: -30px;
	}

	.title {
		@include max-width($tablet_wide) {
			margin: auto;
		}
	}

	.title br {
		display: none;

		@include max-width($tablet_wide) {
			display: initial;
		}
	}

	.gs_button {
		@include max-width($tablet_wide) {
			width: 100%;
		}
	}
}
